<template>
  <div class="order-history">
    <template v-if="region === 'US'">
      <orders-table-us
        tableTitle="US Delivered Orders"
        :orderCall="orderCall"
      />
    </template>
    <template v-else-if="region === 'PAKISTAN'">
      <orders-table-pk
        tableTitle="PK Delivered Orders"
        :orderCall="orderCall"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrdersTableUS from "@/view/pages/orders/us/OrdersHistoryTable";
import OrdersTablePK from "@/view/pages/orders/pk/OrdersHistoryTable";
import { FETCH_ORDER_HISTORY } from "@/core/services/store/orderHistory.module";

export default {
  name: "OrderHistory",
  data: () => ({
    orderCall: FETCH_ORDER_HISTORY
  }),
  components: {
    "orders-table-us": OrdersTableUS,
    "orders-table-pk": OrdersTablePK
  },
  computed: {
    ...mapState({
      region: ({ region }) => region.region
    })
  }
};
</script>

<style lang="scss" scoped></style>
